<template>
  <!--/inner-page-->
  <div class="inner-banner inner-banner-produtos py-5">
    <section class="w3l-breadcrumb text-left py-sm-5">
      <div class="container">
        <div class="w3breadcrumb-gids">
          <div class="w3breadcrumb-left text-left">
            <h2 class="inner-w3-title"><br /></h2>
          </div>
          <div class="w3breadcrumb-right">
            <!-- <ul class="breadcrumbs-custom-path">
              <li><a href="index.html">Home</a></li>
              <li class="active">
                <span class="fas fa-angle-double-right mx-2"></span> Produto xxx
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </section>
  </div>
  <!--//inner-page-->
  <div style="margin: 8px auto; display: block; text-align: center">
    <!---728x90--->
  </div>

  <section class="w3l-ecommerce-main">
    <!-- /products-->
    <div class="ecom-contenthny w3l-ecommerce-main-inn py-5">
      <div class="container py-lg-5">
        <!--/row1-->
        <div class="sp-store-single-page row">
          <div class="col-lg-5 single-right-left">
            <div class="flexslider1" v-show="produto">
              <ul class="slides">
                <li
                  :data-thumb="produto.imagem_1?`${publicUrl}${produto.imagem_1}`:'/assets/images/sem-imagem-produto.jpg'"
                >
                  <div class="thumb-image">
                    <img
                      :src="produto.imagem_1?`${publicUrl}${produto.imagem_1}`:'/assets/images/sem-imagem-produto.jpg'"
                      data-imagezoom="true"
                      class="img-fluid radius-image"
                      alt=" "
                    />
                  </div>
                </li>

                <li
                  v-if="produto.imagem_2"
                  :data-thumb="`${publicUrl}${produto.imagem_2}`"
                >
                  <div class="thumb-image">
                    <img
                      :src="`${publicUrl}${produto.imagem_2}`"
                      data-imagezoom="true"
                      class="img-fluid radius-image"
                      alt=" "
                    />
                  </div>
                </li>

                <li
                  v-if="produto.imagem_3"
                  :data-thumb="`${publicUrl}${produto.imagem_3}`"
                >
                  <div class="thumb-image">
                    <img
                      :src="`${publicUrl}${produto.imagem_3}`"
                      data-imagezoom="true"
                      class="img-fluid radius-image"
                      alt=" "
                    />
                  </div>
                </li>

                <li
                  v-if="produto.imagem_4"
                  :data-thumb="`${publicUrl}${produto.imagem_4}`"
                >
                  <div class="thumb-image">
                    <img
                      :src="`${publicUrl}${produto.imagem_4}`"
                      data-imagezoom="true"
                      class="img-fluid radius-image"
                      alt=" "
                    />
                  </div>
                </li>

                <li
                  v-if="produto.imagem_5"
                  :data-thumb="`${publicUrl}${produto.imagem_5}`"
                >
                  <div class="thumb-image">
                    <img
                      :src="`${publicUrl}${produto.imagem_5}`"
                      data-imagezoom="true"
                      class="img-fluid radius-image"
                      alt=" "
                    />
                  </div>
                </li>
              </ul>
              <div class="clearfix"></div>
            </div>
            <div class="eco-buttons mt-5 d-flex">
              <!-- <div class="shopv single-item">
                <form action="product-single.html#" method="post">
                  <input type="hidden" name="cmd" value="_cart" />
                  <input type="hidden" name="add" value="1" />
                  <input
                    type="hidden"
                    name="shopv_item"
                    value="Cotton Flared Kurta"
                  />
                  <input type="hidden" name="amount" value="599.99" />
                  <button
                    type="submit"
                    class="shopv-cart pshopv-cart add-to-cart btn btn-style btn-primary"
                  >
                    Add to Cart
                  </button>
                </form>
              </div> -->
              <!-- <div class="buyhny-now">
                <a
                  :href="`${baseLinkWhats}${endLinkWhats}`"
                  target="_blank"
                  class="btn btn-style btn-primary"
                  >
                  <i class="fab fa-whatsapp"></i>
                  Solicitar Whatsapp 
                </a>
              </div> -->
            </div>
          </div>
          <div class="col-lg-7 single-right-left ps-lg-5">
            <h3>
              {{ produto.descricao }} 
              {{ produto.veiculo }} 
            </h3>
            <!-- <div class="caption">
              <ul class="rating-single">
                <li>
                  <a href="product-single.html#">
                    <span
                      class="fa fa-star yellow-star"
                      aria-hidden="true"
                    ></span>
                  </a>
                </li>
                <li>
                  <a href="product-single.html#">
                    <span
                      class="fa fa-star yellow-star"
                      aria-hidden="true"
                    ></span>
                  </a>
                </li>
                <li>
                  <a href="product-single.html#">
                    <span
                      class="fa fa-star yellow-star"
                      aria-hidden="true"
                    ></span>
                  </a>
                </li>
                <li>
                  <a href="product-single.html#">
                    <span
                      class="fa fa-star-half-o yellow-star"
                      aria-hidden="true"
                    ></span>
                  </a>
                </li>
                <li>
                  <a href="product-single.html#">
                    <span
                      class="fa fa-star-half yellow-star"
                      aria-hidden="true"
                    ></span>
                  </a>
                </li>
              </ul>

              <h6>
                <span class="item_price">$575</span>
                <del>$1,199</del> Free Delivery
              </h6>
            </div> -->
            <div class="desc_single my-4">
              <ul class="emi-views">
                <li><span>Marca:</span> {{ produto?.marca?.descricao }}</li>
                <li><span>Veiculo:</span> {{ produto.veiculo }}</li>
                <li><span>Lado:</span> {{ produto.lado }}</li>
                <li><span>Referencia:</span> {{ produto.referencia }}</li>
                <li><span>Ano Inicial:</span> {{ produto.ano_inicial }}</li>
                <li><span>Ano Final:</span> {{ produto.ano_final }}</li>
              </ul>
            </div>
            <div class="desc_single mb-4">
              <h5>Observação:</h5>
              <p>{{ produto.obs }}</p>
            </div>
            <!-- <div class="description-apt d-grid mb-4">
              <div class="occasional">
                <h5 class="sp_title mb-3">Highlights:</h5>
                <ul class="single_specific">
                  <li>Neck : Collar Neck</li>
                  <li>Fit : Slim</li>

                  <li>Sleeve : Full Sleeve</li>
                  <li>Material : Pure Cutton</li>
                </ul>
              </div>

              <div class="color-quality">
                <h5 class="sp_title">Services:</h5>
                <ul class="single_serv">
                  <li>
                    <a href="product-single.html#">30 Day Return Policy</a>
                  </li>
                  <li class="gap">
                    <a href="product-single.html#"
                      >Cash on Delivery available</a
                    >
                  </li>
                </ul>
              </div>
            </div> -->
            <br />
            <div class="description mb-4">
              <h5>
                Se interessou? Entre em contato com nossa equipe
              </h5>
              <div class="buyhny-now">
                <a
                  :href="`${baseLinkWhats}${endLinkWhats}`"
                  target="_blank"
                  class="btn btn-style btn-primary whatsapp"
                  >
                  <i class="fab fa-whatsapp"></i>
                  Solicitar Whatsapp 
                </a>
              </div>
              <!-- <h5>
                Check delivery, payment options and charges at your location
              </h5>
              <form action="product-single.html#" class="d-flex" method="post">
                <input type="text" placeholder="Enter pincode" required />
                <button
                  class="submit btn btn-style btn-primary ms-3"
                  type="submit"
                >
                  Check
                </button>
              </form> -->
            </div>
          </div>
        </div>
      </div>
      <!--//row1-->
    </div>
  </section>
</template>

<script>
// import LayoutHeader from "@/views/layout/layout-site-header";
// import LayoutFooter from "@/views/layout/layout-site-footer";

import { onMounted, onUpdated, computed } from "vue";
import useSite from "@/composables/Site";

export default {
  props: {
    id: {
      required: false,
      type: String,
    },
  },

  components: {
    // LayoutHeader,
    // LayoutFooter,
  },

  filters: {
    urlEncode(value) {
      return new URLSearchParams(value)
    }
  },

  setup(props) {
    const { meta, paramsSearch, produto, getProduto } = useSite();

    onMounted(function () {
      getProduto(props.id);
    });

    onUpdated(() => {
      window.makeSlides();
    });

    const endLinkWhats = computed(() => {
      let data = produto.value;
      return encodeURI([
        process.env.VUE_APP_MSG_ORCAMENTO,
        data.referencia, data.descricao
      ].join(' '));
    });

    return {
      paramsSearch,
      meta,
      produto,

      publicUrl: `${process.env.VUE_APP_URL_PUBLIC+'/produto/'}`,
      baseLinkWhats: `${process.env.VUE_APP_URL_WHATSAPP}`,
      endLinkWhats
      
    };
  },
};
</script>

<style></style>
